<template lang="">
    <!-- <div>  -->
	<navigation></navigation> 
	<!-- Page content -->
	<div class="page-content"  >

		
		<leftsidebar></leftsidebar>

 		<router-view></router-view>

		

	<!-- </div> -->
	<!-- /page content -->
    

       
        <footercontainer></footercontainer>
    </div>
</template>
<script>
import navigation from "./navigation.vue"; 
import leftsidebar from "./leftsidebar.vue"; 
import footercontainer from "./footer.vue";
export default {
    components:{ navigation, footercontainer, leftsidebar }
}
</script>
<style lang="">
    
</style>