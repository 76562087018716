import { createStore } from 'vuex'
import loggingService from '../services/loggingService'

const checkIsDarkMode = () => JSON.parse(localStorage.getItem('isDarkMode')) || false

export default createStore({
  state: {
    token: localStorage.token || null,
    settings: localStorage.settings ? JSON.parse(localStorage.settings) : {},
    isImpersonating: false,
    historyRecords: null,
    entityHistory: {
      showHistory: false,
    },
    isAxiosLoading: false,

    layout: {
      isSideBarOpen: false,
      isDarkMode: checkIsDarkMode(),
    },
  },

  mutations: {
    toggleDarkMode(state) {
      const isDarkMode = !state.layout.isDarkMode

      localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode))
      state.layout.isDarkMode = isDarkMode
    },

    toggleSideBar(state) {
      state.layout.isSideBarOpen = !state.layout.isSideBarOpen
    },

    axiosIsLoading(state) {
      state.isAxiosLoading = true
    },

    axiosNotLoading(state) {
      state.isAxiosLoading = false
    },

    showEntityHistory(state, params) {
      loggingService.getEntityHistory(params).then((data) => {
        state.historyRecords = response.data
        state.entityHistory.showHistory = true
        // // //console.log(state.historyRecords.length)
      })
    },

    closeEntityHistory(state, params) {
      state.entityHistory.showHistory = params
    },

    addGoCardlessId(state, params) {
      state.settings.goCardlessId = params
      localStorage.settings = JSON.stringify(state.settings)
    },

    login(state) {
      state.token = null
      state.settings = {}
      localStorage.addItem('token')
    },

    logout(state) {
      localStorage.removeItem('token')
      localStorage.removeItem('settings')
      state.token = null
      state.settings = {}
    },

    toggleImpersonating(state) {
      state.isImpersonating = !state.isImpersonating
    },

    saveToken(state, _token) {
      // ⚠️ misuse of LS
      localStorage.token = _token
      state.token = _token
    },
    saveSettings(state, _settings) {
      localStorage.settings = JSON.stringify(_settings)
      state.settings = JSON.parse(localStorage.settings)
    },
    toggleLeftNav(state) {
      state.layout.leftNavExpanded = !state.layout.leftNavExpanded
    },
    toggleLeftNavMobile(state) {
      state.layout.sidebarMobileExpanded = !state.layout.sidebarMobileExpanded
    },
  },
})
