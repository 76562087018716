<template>
  <!-- Main sidebar -->
  <div class="app app-sidebar dark-menu ps text-white" style="background-color: #2a2e3f; border-right: 1px solid #2a2e3f">
    <div class="main-siderbar-header dark-header active">
      <a class="header-logo active">
        <img src="../assets/images/ppmLogo.png" style="height: 60px" class="main-logo desktop-dark mt-2" alt="" />
      </a>
    </div>
    <!-- Sidebar content -->
    <div class="">
      <!-- Header -->
      <div class="sidebar-section sidebar-header">
        <div class="sidebar-section-body d-flex align-items-center justify-content-center py-2 mb-0">
          <!-- <h5 class="text-white">Main Menu</h5> -->
          <div class="my-1"></div>
        </div>
      </div>
      <!-- /header -->

      <!-- Main navigation -->
      <div class="sidebar-section">
        <ul class="side-menu open">
          <!-- Main -->
          <!-- <li class="nav-item-header pt-0"><div class="text-uppercase font-size-xs line-height-xs">Main</div> <i class="icon-menu" title="Main"></i></li> -->
          <li class="slide">
            <router-link class="side-menu__item" @click="resetAllMenus" :to="{ name: 'dashboard' }">
              <span class="side-menu__label"><i class="fa fa-home" style="font-size: 18px; margin-right: 5px"></i> Dashboard</span>
            </router-link>
          </li>
          <li class="slide">
            <a class="side-menu__item" @click="hideAreaOptions">
              <router-link @click="resetAllMenus" :to="{ name: 'areas' }">
                <span class="side-menu__label"><i class="fa fa-globe-americas" style="font-size: 18px; margin-right: 5px"></i> Areas</span>
              </router-link>
            </a>
            <ul class="slide-menu open" :class="state.areaHidden ? 'shown' : ''">
              <li>
                <router-link class="slide-item" :to="PATHS.AREA_BUILDER"><i class="fa fa-globe-americas" style="font-size: 18px; margin-right: 5px"></i> Build New Area </router-link>
              </li>
            </ul>
          </li>
          <li class="slide">
            <router-link class="side-menu__item" @click="resetAllMenus" :to="{ name: 'clients' }">
              <span class="side-menu__label"><i class="fa fa-user-friends" style="font-size: 18px; margin-right: 5px"></i> Clients</span>
            </router-link>
          </li>
          <li class="slide">
            <router-link class="side-menu__item" @click="resetAllMenus" :to="{ name: 'advertisers' }">
              <span class="side-menu__label"><i class="fa fa-newspaper" style="font-size: 18px; margin-right: 5px"></i> Advertisers</span>
            </router-link>
          </li>
          <li class="slide">
            <router-link class="side-menu__item" @click="resetAllMenus" :to="{ name: 'books' }">
              <span class="side-menu__label"><i class="fa fa-book" style="font-size: 18px; margin-right: 5px"></i> Books</span>
            </router-link>
          </li>
          <li class="slide">
            <router-link class="side-menu__item" @click="resetAllMenus" :to="{ name: 'venues' }">
              <span class="side-menu__label"><i class="fa fa-building" style="font-size: 18px; margin-right: 5px"></i> Venues</span>
            </router-link>
          </li>
          <li class="slide">
            <a class="side-menu__item" @click="hideMapOptions">
              <span class="side-menu__label"><i class="fa fa-globe" style="font-size: 18px; margin-right: 5px"></i> Maps</span>
            </a>
            <ul class="slide-menu open" :class="state.mapHidden ? 'shown' : ''">
              <li>
                <router-link class="slide-item" :to="{ name: 'advertisersmap' }"> Advertisers </router-link>
              </li>
              <li>
                <router-link class="slide-item" :to="{ name: 'areasmap' }"> Areas </router-link>
              </li>
              <li>
                <router-link class="slide-item" :to="{ name: 'venuesmap' }"> Venues </router-link>
              </li>
            </ul>
          </li>
          <li class="slide">
            <a class="side-menu__item" @click="hideVisitOptions">
              <span class="side-menu__label"><i class="fa fa-globe" style="font-size: 18px; margin-right: 5px"></i> Visits / Routes</span>
            </a>
            <ul class="slide-menu open" :class="state.visitsHidden ? 'shown' : ''">
              <li>
                <router-link class="slide-item" :to="{ name: 'visits' }"> Visits </router-link>
              </li>
              <li>
                <router-link class="slide-item" to="/"> Route Manager </router-link>
              </li>
            </ul>
          </li>
          <li class="slide">
            <router-link class="side-menu__item" @click="resetAllMenus" :to="{ name: 'settingcorporateEntities' }">
              <span class="side-menu__label"><i class="fa fa-cog" style="font-size: 18px; margin-right: 5px"></i> Settings</span>
            </router-link>
          </li>
          <li class="slide">
            <router-link class="side-menu__item" @click="resetAllMenus" :to="{ name: 'call-manager' }">
              <span class="side-menu__label"><i class="fa fa-mobile" style="font-size: 18px; margin-right: 5px"></i> Calls</span>
            </router-link>
          </li>
        </ul>
      </div>

      <!-- /main navigation -->
    </div>
    <!-- /sidebar content -->
  </div>
  <!-- /main sidebar -->
</template>
<script setup>
import { reactive } from 'vue'
import { PATHS } from '../router/route-paths'

const state = reactive({
  unfoldBar: false,
  usersDashboard: null,
  mapHidden: false,
  areaHidden: false,
  visitsHidden: false,
  // dropdownNavs: {
  // 	showTrackers: route.meta.navParent  === 'trackers' ,
  // 	showUsers: route.meta.navParent  === 'users',
  // 	showGroups: route.meta.navParent  === 'groups',
  // 	showReports: route.meta.navParent  === 'reports',
  // }
})
const resetAllMenus = function () {
  // state.dropdownNavs.showTrackers = false
  // state.dropdownNavs.showUsers = false
  // state.dropdownNavs.showGroups = false
  // state.dropdownNavs.showReports = false
}

function hideMapOptions() {
  if (state.mapHidden) {
    state.mapHidden = false
  } else {
    state.mapHidden = true
  }
}

function hideVisitOptions() {
  if (state.visitsHidden) {
    state.visitsHidden = false
  } else {
    state.visitsHidden = true
  }
}

function hideAreaOptions() {
  if (state.areaHidden) {
    state.areaHidden = false
  } else {
    state.areaHidden = true
  }
}

const toggleMenu = function (name) {
  // if (name  === 'trackers'){
  // 	if (state.dropdownNavs.showTrackers === true) {
  // 		resetAllMenus()
  // 		state.dropdownNavs.showTrackers = false
  // 	} else {
  // 		resetAllMenus()
  // 		state.dropdownNavs.showTrackers = true
  // 	}
  // } else if (name  === 'users'){
  // 	if (state.dropdownNavs.showUsers) {
  // 		resetAllMenus()
  // 		state.dropdownNavs.showUsers = false
  // 	} else {
  // 		resetAllMenus()
  // 		state.dropdownNavs.showUsers = true
  // 	}
  // }else if (name  === 'groups'){
  // 	if (state.dropdownNavs.showGroups) {
  // 		resetAllMenus()
  // 		state.dropdownNavs.showGroups = false
  // 	} else {
  // 		resetAllMenus()
  // 		state.dropdownNavs.showGroups = true
  // 	}
  // }else if (name  === 'reports'){
  // 	if (state.dropdownNavs.showReports) {
  // 		resetAllMenus()
  // 		state.dropdownNavs.showReports = false
  // 	} else {
  // 		resetAllMenus()
  // 		state.dropdownNavs.showReports = true
  // 	}
  // }
}

const parseUsersDashboard = function () {
  //    state.usersDashboard = 'usersdashboard'	//store.state.settings.dashboard
}

parseUsersDashboard()
</script>
<style scoped>
.shown {
  display: block;
}
</style>
