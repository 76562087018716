import http from '../http-common'

// https://www.bezkoder.com/vue-3-typescript-axios/
class authService {
  login(credentials) {
    // Have to override headers this way in current version of axios..
    return http({ url: '/auth/login', method: 'post', headers: null, data: { userName: credentials.userName, password: credentials.password } })
  }

  Check_UserAccess() {
    return http.get(`/system/Check_UserAccess/`)
  }

  logout(token) {
    return http.post(`/auth/Revoke_UserToken/`, { token: token })
  }
}

export default new authService()
