import http from '../http-common'

class AttachmentsService {
  Set_Attachment(data) {
    return http.post('/attachments/Set_Attachment/', data)
  }

  Set_Avatar(data) {
    return http.post('/attachments/Set_Avatar/', data)
  }

  List_Attachments(entity, id) {
    return http.get('/attachments/List_' + entity + '_Attachments/' + id)
  }

  Get_Attachment(data) {
    return http.post('/attachments/Get_Attachment/', data, {
      responseType: 'blob',
    })
  }

  getAvatar(id) {
    return http.get('/attachments/Get_Avatar/' + id, {
      responseType: 'blob',
    })
  }

  Delete_Attachment(data) {
    return http.get('/attachments/Delete_Attachment/' + data)
  }
}

export default new AttachmentsService()
