<template lang="">
  <div class="page">
    <!-- <div >  -->
    <div class="open">
      <navigation></navigation>
      <leftsidebar></leftsidebar>
    </div>

    <!-- Page content -->

    <!-- Main content -->
    <div class="main-content app-content">
      <div class="main-container container-fluid">
        <router-view></router-view>
      </div>
    </div>
    <!-- /main content -->

    <!-- Right sidebar
		<div class="sidebar sidebar-light sidebar-right sidebar-expand-xl">

			
			<button type="button" class="btn btn-sidebar-expand sidebar-control sidebar-right-toggle">
				<i class="icon-arrow-left12"></i>
			</button>
			


			
			<div class="sidebar-content">

				
				<div class="sidebar-section sidebar-section-body d-flex align-items-center py-2">
					<h5 class="mb-0">Right sidebar</h5>
					<div class="my-1 ml-auto">
						<button type="button" class="btn btn-outline-light text-body border-transparent btn-icon rounded-pill  sidebar-control sidebar-right-toggle d-none d-xl-inline-flex">
							<i class="icon-transmission"></i>
						</button>

						<button type="button" class="btn btn-outline-light text-body border-transparent btn-icon rounded-pill  sidebar-mobile-right-toggle d-xl-none">
							<i class="icon-cross2"></i>
						</button>
					</div>
				</div>
				


				
				<div class="sidebar-section">
					<div class="sidebar-section-header pt-1">
						<span class="font-weight-semibold">Sidebar search</span>
						<div class="list-icons ml-auto">
	                		<a href="#sidebar-search" class="list-icons-item" data-toggle="collapse">
	                			<i class="icon-arrow-down12"></i>
	                		</a>
	                	</div>
					</div>

					<div class="collapse show" id="sidebar-search">
						<form class="sidebar-section-body" action="#">
							<div class="form-group-feedback form-group-feedback-right">
								<input type="search" class="form-control form-control-sm" placeholder="Search">
								<div class="form-control form-control-sm-feedback">
									<i class="icon-search4 font-size-base text-muted"></i>
								</div>
							</div>
						</form>
					</div>
				</div>
				


				
				<div class="sidebar-section">
					<div class="sidebar-section-header">
						<span class="font-weight-semibold">Navigation</span>
						<div class="list-icons ml-auto">
	                		<a href="#sidebar-navigation" class="list-icons-item" data-toggle="collapse">
	                			<i class="icon-arrow-down12"></i>
	                		</a>
	                	</div>
					</div>

					<div class="collapse show" id="sidebar-navigation">
						<ul class="nav nav-sidebar my-2" data-nav-type="accordion">
							<li class="nav-item-header">Header</li>
							<li class="nav-item">
								<a href="#" class="nav-link">Nav item 1</a>
							</li>
							<li class="nav-item">
								<a href="#" class="nav-link">Nav item 2</a>
							</li>
							<li class="nav-item">
								<a href="#" class="nav-link">
									Nav item 3
									<span class="badge badge-primary badge-pill ml-auto">Badge</span>
								</a>
							</li>
							<li class="nav-item-header">Header</li>
							<li class="nav-item">
								<a href="#" class="nav-link">Nav item 4</a>
							</li>
							<li class="nav-item">
								<a href="#" class="nav-link">Nav item 5</a>
							</li>
						</ul>
					</div>
				</div>
				

			</div>
			

		</div>
		/right sidebar -->

    <!-- </div> -->
    <!-- /page content -->

    <footercontainer></footercontainer>
  </div>
</template>
<script>
import navigation from './navigation.vue'
import leftsidebar from './leftsidebar.vue'
import footercontainer from './footer.vue'
import { useStore } from 'vuex'
export default {
  components: { navigation, footercontainer, leftsidebar },
  setup() {
    const store = useStore()

    return {
      store,
    }
  },
}
</script>
<style lang=""></style>
