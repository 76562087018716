<template>
    <div class="open">
        <!-- main-header -->
        <div class="main-header sticky nav nav-item hor-header" style="margin-bottom: -63px">
            <div class="main-container container">
                <div class="main-header-left">
                    <div class="responsive-logo">
                        <a href="/" class="header-logo"> <img src="../../assets/images/logo.png"
                                class="mobile-logo logo-1" alt="logo" /> <img src="../../assets/images/logo.png"
                                class="mobile-logo dark-logo-1" alt="logo" /> </a>
                    </div>
                    <div class="app-sidebar__toggle" data-bs-toggle="sidebar">
                        <a class="open-toggle" href="javascript:void(0);"><i
                                class="header-icon fe fe-align-left"></i></a> <a class="close-toggle"
                            href="javascript:void(0);"><i class="header-icon fe fe-x"></i></a>
                    </div>
                    <div class="logo-horizontal">
                        <a href="/" class="header-logo"> <img src="../../assets/images/logo.png"
                                class="mobile-logo logo-1" alt="logo" /> <img src="../../assets/images/logo.png"
                                style="width: auto; height: 42px" class="mobile-logo dark-logo-1" alt="logo" /> </a>
                    </div>
                    <div class="main-header-center ms-4 d-sm-none d-md-none d-lg-block form-group"
                        style="position: relative">
                        <input @keyup="search" v-model="state.searchValue" class="form-control form-control-sm"
                            placeholder="Search..." type="search" /> <button class="btn"><i
                                class="fas fa-search"></i></button>
                        <div v-if="state.results.length" class="bg-light"
                            style="border-radius: 0px 0px 5px 5px; position: absolute; left: 2px; bottom: -200px; height: 200px; width: 495px; -webkit-box-shadow: 0px 3px 5px -3px rgba(0, 0, 0, 0.75); -moz-box-shadow: 0px 3px 5px -3px rgba(0, 0, 0, 0.75); box-shadow: 0px 3px 5px -3px rgba(0, 0, 0, 0.75)">
                            <div class="searchResultsBox">
                                <div v-for="(record, index) in state.results" :key="index">
                                    <div @click="goToRecord(record)" class="row searchResultsRow">
                                        <div class="col-auto">
                                            <strong>#{{ record.id }}</strong>
                                        </div>
                                        <div class="col">
                                            {{ record.name }}
                                        </div>
                                        <div class="col-auto">
                                            {{ record.entityName }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="text-center">
                                <a @click="closeSearch" class="text-center"><strong><i class="fa fa-times"></i> Close
                                        Search</strong></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main-header-right">
                    <button class="navbar-toggler navresponsive-toggler d-lg-none ms-auto" type="button"
                        data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent-4"
                        aria-controls="navbarSupportedContent-4" aria-expanded="false"
                        aria-label="Toggle navigation"><span
                            class="navbar-toggler-icon fe fe-more-vertical"></span></button>
                    <div class="mb-0 navbar navbar-expand-lg navbar-nav-right responsive-navbar navbar-dark p-0">
                        <div class="collapse navbar-collapse" id="navbarSupportedContent-4">
                            <ul class="nav nav-item header-icons navbar-nav-right ms-auto">
                                <li class="dropdown nav-item">
                                    <a @click="toggleDarkMode"
                                        class="new nav-link theme-layout nav-link-bg layout-setting">
                                        <span class="dark-layout">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="header-icon-svgs" width="24"
                                                height="24" viewBox="0 0 24 24">
                                                <path
                                                    d="M20.742 13.045a8.088 8.088 0 0 1-2.077.271c-2.135 0-4.14-.83-5.646-2.336a8.025 8.025 0 0 1-2.064-7.723A1 1 0 0 0 9.73 2.034a10.014 10.014 0 0 0-4.489 2.582c-3.898 3.898-3.898 10.243 0 14.143a9.937 9.937 0 0 0 7.072 2.93 9.93 9.93 0 0 0 7.07-2.929 10.007 10.007 0 0 0 2.583-4.491 1.001 1.001 0 0 0-1.224-1.224zm-2.772 4.301a7.947 7.947 0 0 1-5.656 2.343 7.953 7.953 0 0 1-5.658-2.344c-3.118-3.119-3.118-8.195 0-11.314a7.923 7.923 0 0 1 2.06-1.483 10.027 10.027 0 0 0 2.89 7.848 9.972 9.972 0 0 0 7.848 2.891 8.036 8.036 0 0 1-1.484 2.059z">
                                                </path>
                                            </svg>
                                        </span>
                                        <span class="light-layout">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="header-icon-svgs" width="24"
                                                height="24" viewBox="0 0 24 24">
                                                <path
                                                    d="M6.993 12c0 2.761 2.246 5.007 5.007 5.007s5.007-2.246 5.007-5.007S14.761 6.993 12 6.993 6.993 9.239 6.993 12zM12 8.993c1.658 0 3.007 1.349 3.007 3.007S13.658 15.007 12 15.007 8.993 13.658 8.993 12 10.342 8.993 12 8.993zM10.998 19h2v3h-2zm0-17h2v3h-2zm-9 9h3v2h-3zm17 0h3v2h-3zM4.219 18.363l2.12-2.122 1.415 1.414-2.12 2.122zM16.24 6.344l2.122-2.122 1.414 1.414-2.122 2.122zM6.342 7.759 4.22 5.637l1.415-1.414 2.12 2.122zm13.434 10.605-1.414 1.414-2.122-2.122 1.414-1.414z">
                                                </path>
                                            </svg>
                                        </span>
                                    </a>
                                </li>

                                <li class="dropdown nav-item main-header-notification d-flex">
                                    <!-- <a class="new nav-link" data-bs-toggle="dropdown" href="javascript:void(0);">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="header-icon-svgs" width="24" height="24" viewBox="0 0 24 24"><path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z"></path></svg><span class="pulse"></span>
                                    </a>
                                    <div class="dropdown-menu">
                                        <div class="menu-header-content text-start border-bottom">
                                            <div class="d-flex">
                                                <h6 class="dropdown-title mb-1 tx-15 font-weight-semibold">Notifications</h6>
                                                <span class="badge badge-pill badge-warning ms-auto my-auto float-end">Mark All Read</span>
                                            </div>
                                            <p class="dropdown-title-text subtext mb-0 op-6 pb-0 tx-12">You have 4 unread Notifications</p>
                                        </div>
                                        <div class="main-notification-list Notification-scroll ps">
                                            <a class="d-flex p-3 border-bottom" href="mail.html">
                                                <div class="notifyimg bg-pink"><i class="far fa-folder-open text-white"></i></div>
                                                <div class="ms-3">
                                                    <h5 class="notification-label mb-1">New files available</h5>
                                                    <div class="notification-subtext">10 hour ago</div>
                                                </div>
                                                <div class="ms-auto"><i class="las la-angle-right text-end text-muted"></i></div>
                                            </a>
                                            <a class="d-flex p-3 border-bottom" href="mail.html">
                                                <div class="notifyimg bg-warning"><i class="far fa-envelope-open text-white"></i></div>
                                                <div class="ms-3">
                                                    <h5 class="notification-label mb-1">New Route Assigned</h5>
                                                    <div class="notification-subtext">1 day ago</div>
                                                </div>
                                                <div class="ms-auto"><i class="las la-angle-right text-end text-muted"></i></div>
                                            </a>

                                            <div class="ps__rail-x" style="left: 0px; bottom: 0px"><div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px"></div></div>
                                            <div class="ps__rail-y" style="top: 0px; right: 0px"><div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px"></div></div>
                                        </div>
                                        <div class="dropdown-footer"><a class="btn btn-primary btn-sm btn-block" href="mail.html">VIEW ALL</a></div>
                                    </div> -->
                                    <notifications></notifications>
                                </li>
                                <li class="nav-item full-screen fullscreen-button">
                                    <a @click="toggleFullScreen" class="new nav-link full-screen-link"
                                        href="javascript:void(0);">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="header-icon-svgs" width="24"
                                            height="24" viewBox="0 0 24 24">
                                            <path
                                                d="M5 5h5V3H3v7h2zm5 14H5v-5H3v7h7zm11-5h-2v5h-5v2h7zm-2-4h2V3h-7v2h5z">
                                            </path>
                                        </svg>
                                    </a>
                                </li>
                                <li class="nav-link search-icon d-lg-none d-block">
                                    <form class="navbar-form" role="searchy">
                                        <div class="input-group">
                                            <input type="text" class="form-control form-control-sm"
                                                placeholder="Search" />
                                            <span class="input-group-btn">
                                                <button type="reset" class="btn btn-default"><i
                                                        class="fas fa-times"></i></button>
                                                <button type="submit" class="btn btn-default nav-link resp-btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="24px"
                                                        class="header-icon-svgs" viewBox="0 0 24 24" width="24px"
                                                        fill="#000000">
                                                        <path d="M0 0h24v24H0V0z" fill="none"></path>
                                                        <path
                                                            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z">
                                                        </path>
                                                    </svg>
                                                </button>
                                            </span>
                                        </div>
                                    </form>
                                </li>
                                <li class="dropdown main-profile-menu nav nav-item nav-link ps-lg-2">
                                    <a class="new nav-link profile-user d-flex" href="" data-bs-toggle="dropdown">
                                        <avatar :userId="null" :width="40" :height="40"></avatar>
                                    </a>
                                    <div class="dropdown-menu">
                                        <div class="menu-header-content p-3 border-bottom">
                                            <div class="d-flex wd-100p">
                                                <div class="main-img-user">
                                                    <avatar :userId="null" :width="50" :height="50"></avatar>
                                                </div>
                                                <div class="ms-3 my-auto">
                                                    <h6 class="tx-15 font-weight-semibold mb-0">{{
                                                        store.state.settings.firstName + ' ' +
                                                        store.state.settings.lastName }}</h6>
                                                    <span
                                                        class="dropdown-title-text subtext op-6 tx-12">Administrator</span>
                                                </div>
                                            </div>
                                        </div>
                                        <router-link class="dropdown-item" :to="{ name: 'myprofile' }">
                                            <a><i class="far fa-user-circle me-2"></i> My Profile</a>
                                        </router-link>
                                        <router-link :to="{ name: 'mycalendar' }" class="dropdown-item">
                                            <a><i class="far fa-calendar me-2"></i> My Calendar</a>
                                        </router-link>
                                        <router-link :to="{ name: 'mytasks' }" class="dropdown-item">
                                            <a><i class="far fa-clipboard me-2"></i> My Tasks</a>
                                        </router-link>
                                        <!-- <router-link :to="{ name: 'myvisits' }" class="dropdown-item">
                                            <a><i class="far fa-map me-2"></i> My Visits</a>
                                        </router-link> -->
                                        <!-- <router-link :to="{ name: 'myprofile', query: { tab: 2 } }" class="dropdown-item">
                                            <a><i class="far fa-sun me-2"></i> Account settings</a>
                                        </router-link> -->
                                        <a @click="exitApp" class="dropdown-item"><i
                                                class="far fa-arrow-alt-circle-left me-2"></i> Sign Out</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="jumps-prevent" style="padding-top: 63px"></div>
        <!-- /main-header -->
        <navmenu></navmenu>
    </div>
</template>

<script>
import { reactive } from 'vue'
import navmenu from './navmenu.vue'
import notifications from '../../components/generic/notifications/notifications.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import authService from '../../services/authService'
import searchService from '../../services/searchService'
import Avatar from '../../components/generic/avatar/avatar.vue'
export default {
    components: { navmenu, notifications, Avatar },
    setup() {
        const state = new reactive({
            searchValue: '',
            results: [],
        })

        const store = useStore()
        const router = useRouter()

        const toggleDarkMode = () => {
            const bodyEl = document.querySelector('body')

            bodyEl.classList.toggle('light-theme')
            bodyEl.classList.toggle('dark-theme')

            store.commit('toggleDarkMode')
        }

        function toggleFullScreen() {
            let elem = document.body
            // ## The below if statement seems to work better ## if ((document.fullScreenElement && document.fullScreenElement) || (document.msfullscreenElement && document.msfullscreenElement) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if ((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)) {
                if (elem.requestFullScreen) {
                    elem.requestFullScreen()
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen()
                } else if (elem.webkitRequestFullScreen) {
                    elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT)
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen()
                }
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen()
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen()
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen()
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen()
                }
            }
        }

        function goToRecord(record) {
            if (record.entityName === 'Area') {
                router.push({ name: '/areas/' + record.id })
            }
            if (record.entityName === 'Venue') {
                router.push({ path: '/venues/' + record.id })
            }
            if (record.entityName === 'Client') {
                router.push({ path: '/clients/' + record.id })
            }

            if (record.entityName === 'Advertiser') {
                router.push({ path: '/advertisers/' + record.id })
            }
            state.results = []
            state.searchValue = ''
        }

        function closeSearch() {
            state.results = []
        }

        const exitApp = function () {
            authService
                .logout(store.state.token)
                .then(() => {
                    store.commit('logout')
                    router.push({ name: 'login' })
                })
                .catch((err) => {
                    toast.error('There has been an error')
                })
            // loggingService.Log_UserConnection(2).then(() => {
            //     store.commit('logout')
            //     router.push({ name: 'login' })
            // })
        }

        function search() {
            if (state.searchValue) {
                searchService
                    .Search_CRM(state.searchValue)
                    .then((data) => {
                        state.results = data
                    })
                    .catch((err) => {
                        toast.error('There has been an error')
                    })
            }
        }

        return {
            exitApp,
            toggleDarkMode,
            toggleFullScreen,
            store,
            state,
            search,
            goToRecord,
            closeSearch,
        }
    },
}
</script>
<style>
.searchCloser {
    cursor: pointer;
}

.searchResultsBox {
    padding: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 178px;
    margin-bottom: 2px;
}

.searchResultsRow:hover {
    background-color: #8045ba;
    color: #fff;
    cursor: pointer;
}
</style>
