export const PATHS = {
  DASHBOARD: '/dashboard',

  BOTS: '/bots',
  BOT_DETAIL: '/bots/:id',

  VENUES: '/venues',
  VENUE_DETAIL: '/venues/:id',

  CLIENTS: '/clients',
  CLIENT_DETAIL: '/clients/:id',

  AREAS: '/areas',
  AREA_BUILDER: '/areas/build',
  AREA_DETAIL: '/areas/:id',

  ADVERTISERS: '/advertisers',
  ADVERTISER_DETAIL: '/advertisers/:id',
}
