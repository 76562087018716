<template lang="">
  <div class="page">
    <headercontainer></headercontainer>
    <!-- Main content -->
    <div class="main-content horizontal-content">
      <div class="main-container container">
        <router-view></router-view>
      </div>
    </div>
    <vue-progress-bar></vue-progress-bar>
    <!-- End Main content -->
    <footercontainer></footercontainer>
  </div>
</template>
<script setup>
import { getCurrentInstance, watch } from 'vue'
import { useStore } from 'vuex'
import footercontainer from '../footer.vue'
import headercontainer from './headercontainer.vue'

const internalInstance = getCurrentInstance()

const store = useStore()

watch(
  () => store.state.isAxiosLoading,
  (first, second) => {
    const { $Progress } = internalInstance.appContext.config.globalProperties
    $Progress[first ? 'start' : 'finish']()
  }
)
</script>
<style scoped>
.fade-enter-from {
  opacity: 0;
}
.fade-enter-active {
  transition: all 1s ease;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  transition: all 1s ease;
}
</style>
