import http from '../http-common'

class UserService {
  List_Team_Users(id) {
    return http.get(`/users/List_Team_Users/` + id)
  }
  List_Teams() {
    return http.get(`/users/List_Teams/`)
  }
  Set_Team_User(data) {
    return http.post(`/users/Set_Team_User/`, data)
  }
  Set_Team(data) {
    return http.post(`/users/Set_Team/`, data)
  }

  Get_User(id) {
    return http.get(`/users/Get_UserDetails/` + id)
  }
  Set_User(data) {
    return http.post(`/users/Set_User/`, data)
  }
  List_Users() {
    return http.get(`/users/List_Users/`)
  }
  Set_UserPassword(data) {
    return http.post(`/users/Set_UserPassword/`, data)
  }
  Confirm_PasswordReset(data) {
    return http.post('/users/confirmPasswordReset/', data)
  }
  complete_Onboarding(data) {
    return http.post('/users/complete_Onboarding/', data)
  }
  Request_PasswordReset(id) {
    return http.get('/users/requestPasswordReset/' + id)
  }
  Get_UserSettings() {
    return http.get('/users/Get_UserSettings/')
  }

  Get_UserProfile() {
    return http.get('/users/Get_UserProfile/')
  }

  Set_MyUser(data) {
    return http.post('/users/Set_MyUser/', data)
  }
}

export default new UserService()
