<template>
    <div id="global-loader"><img src="./assets/images/loader.svg" class="loader-img" alt="Loader" /></div>
    <component :is="layout"></component>
    <a-drawer title="Record History" placement="bottom" :closable="true" height="50%" @close="$store.commit('closeEntityHistory', false)" v-model:visible="$store.state.entityHistory.showHistory">
        <a-collapse v-model:activeKey="state.activeKey" v-if="$store.state.historyRecords && $store.state.historyRecords.length > 0">
            <template v-if="$store.state.historyRecords.length > 0">
                <a-collapse-panel v-for="record in $store.state.historyRecords" :key="record.createdDate" :header="record.createdByUser + ' On: ' + moment(record.createdDate).locale('en-gb').format('DD MMM YYYY hh:mm:ss a')">
                    <vue-json-pretty :path="'res'" :data="JSON.parse(record.jsonData)"> </vue-json-pretty>
                </a-collapse-panel>
            </template>
        </a-collapse>
        <p v-else>No History To Show</p>
    </a-drawer>
</template>

<script>
import { defineComponent, inject, reactive, onMounted } from 'vue'
import defaultlayout from './layouts/mainlayout.vue'
import defaultlayouthorizontal from './layouts/horizontal/mainlayout.vue'
import maplayout from './layouts/maplayout.vue'
import blanklayout from './layouts/blanklayout.vue'
import VueJsonPretty from 'vue-json-pretty'
import usersService from './services/user-service'
import { useToast } from 'vue-toastification'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
    components: {
        VueJsonPretty,

        'default-layout-horizontal': defaultlayouthorizontal,
        'default-layout': defaultlayout,
        'blank-layout': blanklayout,
        'map-layout': maplayout,
    },
    computed: {
        layout() {
            return this.$route.meta.layout
        },
    },

    setup() {
        const moment = inject('$moment')

        const state = reactive({
            activeKey: null,
        })

        const store = useStore()

        const toast = useToast()

        const router = useRouter()

        if (store.state.layout.isDarkMode) {
            const bodyEl = document.querySelector('body')

            bodyEl.classList.toggle('light-theme')
            bodyEl.classList.toggle('dark-theme')
        }

        function resetUser() {
            store.commit('saveToken', localStorage.originToken)
            localStorage.removeItem('originToken')

            usersService
                .Get_UserSettings()
                .then((data) => {
                    store.commit('saveSettings', data)
                    router.push('/')
                })
                .catch((error) => {
                    toast.error('There has been an error')
                })
        }

        onMounted(() => {
            //save this key to a .env file if applicable for vite..
            const key = 'AIzaSyBWevLcya8iVui2OYKviCUkmW0k01FI2Eg' //'AIzaSyBYS6XoDILp4EWzCK8dmTsVa3ByP27RTe4'
            // create the script element to load
            const googleMapScript = document.createElement('SCRIPT')
            googleMapScript.setAttribute('src', `https://maps.googleapis.com/maps/api/js?key=${key}&callback=initMap&v=beta&libraries=marker,places`)
            googleMapScript.setAttribute('defer', '')
            googleMapScript.setAttribute('async', '')
            // add any event listeners here
            // googleMapScript.addEventListener('click', (event) => {
            //   // //alert('clicked me')
            // })

            document.head.appendChild(googleMapScript)
        })

        window.initMap = () => {
            // google maps complains if we dont have this..
        }

        return { moment, state, resetUser, store }
    },
}
</script>

<style lang="scss">
//@import './assets/scss/style.scss';
#global-loader {
    filter: hue-rotate(0.3turn);
}
</style>
