<template>
  <!-- main-sidebar -->
  <div class="sticky" style="margin-bottom: -63px">
    <aside class="app-sidebar ps ps--active-y horizontal-main">
      <div class="main-sidebar-header active"><a class="header-logo active" href="index.html"> </a></div>
      <div class="main-sidemenu is-expanded container">
        <div @click="slideRight" class="slide-left   active  " id="slide-left">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="18" height="18" viewBox="0 0 24 24">
            <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"></path>
          </svg>
        </div>
        <ul :style="{ 'marginLeft': state.sliderMargin + 'px !important' }" class="side-menu open"
          style="margin-right: 0px;  flex-wrap: nowrap">
          <li class="slide">
            <router-link class="side-menu__item" @click="resetAllMenus" :to="{ name: 'dashboard' }">
              <span class="side-menu__label"><i class="fa fa-home"
                  style="font-size: 16px; margin-right: 5px; color: #8045ba !important"></i> </span>
            </router-link>
          </li>
          <!-- <li class="slide" v-if="$hasPermission('View Areas')">
            <a class="side-menu__item" :class="{ active: routeName === 'areas' }" @click="hideAreaOptions">
              <router-link @click="resetAllMenus" :to="{ name: 'areas' }">
                <span class="side-menu__label"><i class="fa fa-globe-americas"
                    style="font-size: 16px; margin-right: 5px"></i> Areas</span>
              </router-link>
            </a>
            <ul class="slide-menu open" :class="state.areaHidden ? 'shown' : ''">
              <li>
                <router-link class="slide-item" :to="PATHS.AREA_BUILDER"><i class="fa fa-globe-americas"
                    style="font-size: 16px; margin-right: 5px"></i> Build New Area </router-link>
              </li>
            </ul>
          </li> -->
          <li class="slide" v-if="$hasPermission('View Clients')">
            <router-link :class="{ active: routeName === 'clients' }" class="side-menu__item" @click="resetAllMenus"
              :to="{ name: 'clients' }">
              <span class="side-menu__label"><i class="fa fa-user-friends"
                  style="font-size: 16px; margin-right: 5px"></i> Clients</span>
            </router-link>
          </li>
          <li class="slide">
            <router-link :class="{ 'side-menu__item active': routeName === 'advertisers' }" @click="resetAllMenus"
              :to="{ name: 'advertisers' }">
              <span class="side-menu__label active"><i class="fa fa-newspaper"
                  style="font-size: 16px; margin-right: 5px"></i> Advertisers</span>
            </router-link>
          </li>
          <li class="slide">
            <router-link :class="{ 'side-menu__item active': routeName === 'leads' }" @click="resetAllMenus"
              :to="{ name: 'leads' }">
              <span class="side-menu__label active"><i class="fa fa-user-plus"
                  style="font-size: 16px; margin-right: 5px"></i> Leads</span>
            </router-link>
            <ul class="slide-menu open" :class="state.mapHidden ? 'shown' : ''">
              <li>
                <router-link class="slide-item" :to="{ name: 'leadsimport' }"> Leads Import </router-link>
              </li>
              <li>
                <router-link class="slide-item" :to="{ name: 'zoomsearch' }"> Zoom Search </router-link>
              </li>
            </ul>
          </li>
          <li class="slide" v-if="$hasPermission('View Books')">
            <router-link :class="{ active: routeName === 'books' }" class="side-menu__item" @click="resetAllMenus"
              :to="{ name: 'books' }">
              <span class="side-menu__label"><i class="fa fa-book" style="font-size: 16px; margin-right: 5px"></i>
                Books</span>
            </router-link>
          </li>
          <!-- <li class="slide">
                        <router-link class="side-menu__item"@click="resetAllMenus" :to="{ name: 'venues' }">
                            <span class="side-menu__label"><i class="fa fa-building" style="font-size: 16px; margin-right: 5px"></i> Venues</span>
                        </router-link>
                    </li> -->
          <li class="slide">
            <router-link :class="{ 'side-menu__item active': routeName === 'venues' }" @click="resetAllMenus"
              :to="{ name: 'venues' }">
              <span class="side-menu__label active"><i class="fa fa-globe"
                  style="font-size: 16px; margin-right: 5px"></i> Venues</span>
            </router-link>
            <ul class="slide-menu open" :class="state.mapHidden ? 'shown' : ''">
              <li>
                <router-link class="slide-item" :to="{ name: 'routes' }"> Routes </router-link>
              </li>
              <!-- <li>
                                <router-link class="slide-item" :to="{ name: 'routes', query: { userId: store.state.settings.userId } }"> My Routes </router-link>
                            </li> -->
              <li>
                <router-link class="slide-item" to="/"> New Route </router-link>
              </li>
            </ul>
          </li>
          <li class="slide" v-if="$hasPermission('View Maps')">
            <a :class="{ active: routeName === 'maps' }" class="side-menu__item" @click="hideMapOptions">
              <span class="side-menu__label"><i class="fa fa-globe" style="font-size: 16px; margin-right: 5px"></i>
                Maps</span>
            </a>
            <ul class="slide-menu open" :class="state.mapHidden ? 'shown' : ''">
              <li>
                <router-link class="slide-item" :to="{ name: 'advertisersmap' }"> Advertisers </router-link>
              </li>
              <li>
                <router-link class="slide-item" :to="{ name: 'areasmap' }"> Areas </router-link>
              </li>
              <li>
                <router-link class="slide-item" :to="{ name: 'venuesmap' }"> Venues </router-link>
              </li>
              <li>
                <router-link class="slide-item" :to="{ name: 'maptool' }"> Map Tool </router-link>
              </li>
            </ul>
          </li>
          <li class="slide" v-if="$hasPermission('View Visits')">
            <router-link :class="{ active: routeName === 'visits' }" class="side-menu__item" @click="resetAllMenus"
              :to="{ name: 'visits' }">
              <span class="side-menu__label"><i class="fa fa-map-pin" style="font-size: 16px; margin-right: 5px"></i>
                Visits</span>
            </router-link>
          </li>
          <!-- <li class="slide" v-if="$hasPermission('View Routes')">
                        <a class="side-menu__item" @click="hideVisitOptions">
                            <span class="side-menu__label"><i class="fa fa-map" style="font-size: 16px; margin-right: 5px"></i> Routes</span>
                        </a>
                        <ul class="slide-menu open" :class="state.visitsHidden ? 'shown' : ''">
                          
                        </ul>
                    </li> -->
          <li class="slide" v-if="$hasPermission('View All Tasks')">
            <router-link class="side-menu__item" :class="{ active: routeName === 'tasks' }" @click="resetAllMenus"
              :to="{ name: 'tasks' }">
              <span class="side-menu__label"><i class="fa fa-clipboard" style="font-size: 16px; margin-right: 5px"></i>
                Tasks</span>
            </router-link>
            <ul class="slide-menu open" :class="state.tasksHidden ? 'shown' : ''">
              <li>
                <router-link class="slide-item" :to="{ name: 'actions' }"> Action History </router-link>
              </li>
            </ul>
          </li>
          <li class="slide" v-if="$hasPermission('View Invoices')">
            <router-link class="side-menu__item" :class="{ active: routeName === 'billing' }" @click="resetAllMenus"
              :to="{ name: 'billing' }">
              <span class="side-menu__label"><i class="fa fa-dollar-sign"
                  style="font-size: 16px; margin-right: 5px"></i> Billing</span>
            </router-link>
            <ul class="slide-menu open" :class="state.tasksHidden ? 'shown' : ''">
              <li>
                <router-link class="slide-item" :to="{ name: 'payments' }"> Payments </router-link>
              </li>
            </ul>
          </li>
          <li class="slide">
            <a :class="{ active: routeName === 'settings' }" class="side-menu__item" @click="hideVisitOptions">
              <span class="side-menu__label"><i class="fa fa-cog" style="font-size: 16px; margin-right: 5px"></i>
                Settings {{ state.sliderMargin }}</span>
            </a>
            <ul class="slide-menu open" :class="state.visitsHidden ? 'shown' : ''">
              <li v-if="$hasPermission('Add / Edit General Settings')">
                <router-link class="slide-item" :to="{ name: 'settingcorporateEntities' }"> General Settings
                </router-link>
              </li>
              <li v-if="$hasPermission('View Email Templates')">
                <router-link class="slide-item" :to="{ name: 'emailtemplates' }"> Email Templates </router-link>
              </li>
            </ul>
          </li>
          <!-- <li class="slide" v-if="$hasPermission('List Calls')">
                        <router-link class="side-menu__item" :class="{ active: routeName === 'call-manager' }"@click="resetAllMenus" :to="{ name: 'call-manager' }">
                            <span class="side-menu__label"><i class="fa fa-mobile" style="font-size: 16px; margin-right: 5px"></i> Calls</span>
                        </router-link>
                    </li> -->
          <li class="slide">
            <a :class="{ active: routeName === 'emails' || routeName === 'call-manager' }" class="side-menu__item"
              @click="hideCommsOptions">
              <span class="side-menu__label"><i class="fa fa-phone" style="font-size: 16px; margin-right: 5px"></i>
                Comms</span>
            </a>
            <ul class="slide-menu open">
              <li v-if="$hasPermission('View Emails')">
                <router-link class="slide-item" :to="{ name: 'emails' }"> Emails </router-link>
              </li>
              <li v-if="$hasPermission('List Calls')">
                <router-link class="slide-item" :to="{ name: 'call-manager' }"> Calls </router-link>
              </li>
            </ul>
          </li>

          <!-- <li class="slide" v-if="$hasPermission('View Emails')">
                        <router-link class="side-menu__item" :class="{ active: routeName === 'emails' }"@click="resetAllMenus" :to="{ name: 'emails' }">
                            <span class="side-menu__label"><i class="fa fa-envelope" style="font-size: 16px; margin-right: 5px"></i> Emails</span>
                        </router-link>
                    </li> -->
          <li class="slide" v-if="$hasPermission('View Reports')">
            <a class="side-menu__item" :class="{ active: routeName === 'reports' }" @click="hideVisitOptions">
              <span class="side-menu__label"><i class="fa fa-chart-area" style="font-size: 16px; margin-right: 5px"></i>
                Reports</span>
            </a>
            <ul class="slide-menu" :class="state.visitsHidden ? 'shown' : ''">
              <li class="sub-slide">
                <a class="slide-item" data-bs-toggle="sub-slide" href="javascript:void(0);"><span
                    class="sub-side-menu__label">Clients</span><i class="sub-angle fe fe-chevron-right"></i></a>
                <ul style="width: 250px" class="sub-slide-menu">
                  <li><router-link class="slide-item" :to="{ name: 'clientconversionreport' }"> Client Conversions
                    </router-link></li>
                  <li><router-link class="slide-item" :to="{ name: 'clientchurnreport' }"> Client Churn </router-link>
                  </li>
                </ul>
              </li>
              <li class="sub-slide">
                <a class="slide-item" data-bs-toggle="sub-slide" href="javascript:void(0);"><span
                    class="sub-side-menu__label">Venues</span><i class="sub-angle fe fe-chevron-right"></i></a>
                <ul style="width: 250px" class="sub-slide-menu">
                  <li><router-link class="slide-item" :to="{ name: 'venueconversionreport' }"> Venue Conversions
                    </router-link></li>
                  <li><router-link class="slide-item" :to="{ name: 'venuechurnreport' }"> Venue Churn </router-link>
                  </li>
                </ul>
              </li>
              <li class="sub-slide">
                <a class="slide-item" data-bs-toggle="sub-slide" href="javascript:void(0);"><span
                    class="sub-side-menu__label">Advertisers</span><i class="sub-angle fe fe-chevron-right"></i></a>
                <ul style="width: 250px" class="sub-slide-menu">
                  <li><router-link class="slide-item" :to="{ name: 'advertiserconversionreport' }"> Advertiser
                      Conversions </router-link></li>
                  <li><router-link class="slide-item" :to="{ name: 'advertiserchurnreport' }"> Advertiser Churn
                    </router-link></li>
                </ul>
              </li>
              <li class="sub-slide">
                <a class="slide-item" data-bs-toggle="sub-slide" href="javascript:void(0);"><span
                    class="sub-side-menu__label">Sales</span><i class="sub-angle fe fe-chevron-right"></i></a>
                <ul style="width: 250px" class="sub-slide-menu">
                  <li><router-link class="slide-item" :to="{ name: 'comissionsummaryreport' }"> Commision Summary
                    </router-link></li>
                </ul>
              </li>
              <li class="sub-slide">
                <a class="slide-item" data-bs-toggle="sub-slide" href="javascript:void(0);"><span
                    class="sub-side-menu__label">Management</span><i class="sub-angle fe fe-chevron-right"></i></a>
                <ul style="width: 250px" class="sub-slide-menu">
                  <li><router-link class="slide-item" :to="{ name: 'revenueoverviewreport' }"> Revenue Overview
                    </router-link></li>
                  <li><router-link class="slide-item" :to="{ name: 'agentperformancereport' }"> Agent Performance
                    </router-link></li>
                </ul>
              </li>
              <li class="sub-slide">
                <a class="slide-item" data-bs-toggle="sub-slide" href="javascript:void(0);"><span
                    class="sub-side-menu__label">Visits</span><i class="sub-angle fe fe-chevron-right"></i></a>
                <ul style="width: 250px" class="sub-slide-menu">
                  <li><router-link class="slide-item" :to="{ name: 'visitsbyuserreport' }"> Visits By User
                    </router-link></li>
                </ul>
              </li>
            </ul>
          </li>
          <!-- NEW BOT MENU ITEM-->
          <!-- <li class="slide" v-if="$hasPermission('List Calls')">
            <router-link class="side-menu__item" @click="resetAllMenus" :to="{ name: 'bots' }">
              <span class="side-menu__label"><i class="fa fa-robot" style="font-size: 16px; margin-right: 5px"></i>
                BOT</span>
            </router-link>
          </li> -->
        </ul>
        <div @click="slideLeft" class="slide-right" id="slide-right">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="18" height="18" viewBox="0 0 24 24">
            <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path>
          </svg>
        </div>
      </div>
      <div class="ps__rail-x" style="left: 0px; bottom: 0px">
        <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px"></div>
      </div>
      <div class="ps__rail-y" style="top: 0px; height: 603px; right: 0px">
        <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 439px"></div>
      </div>
    </aside>
  </div>
  <div class="jumps-prevent" style="padding-top: 63px"></div>
  <!-- main-sidebar -->
</template>

<script setup>
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { ref, reactive, computed } from 'vue'
import { PATHS } from '../../router/route-paths'

const route = useRoute()
const store = useStore()
const state = reactive({
  unfoldBar: false,
  usersDashboard: null,
  mapHidden: false,
  areaHidden: false,
  visitsHidden: false,
  tasksHidden: false,
  venuesHidden: false,
  sliderMargin: 0,

  //   areShowns: {
  //     map: true,
  //     area: true,
  //     visits: true,
  //     tasks: true,
  //     venues: true,
  //   },

  // dropdownNavs: {
  // 	showTrackers: route.meta.navParent  === 'trackers' ,
  // 	showUsers: route.meta.navParent  === 'users',
  // 	showGroups: route.meta.navParent  === 'groups',
  // 	showReports: route.meta.navParent  === 'reports',
  // }
})

const routeName = computed(() => route.name)

const resetAllMenus = function () {
  // state.dropdownNavs.showTrackers = false
  // state.dropdownNavs.showUsers = false
  // state.dropdownNavs.showGroups = false
  // state.dropdownNavs.showReports = false
}

// const toggleVisibilityOf = (key) => {
//   state.areShowns[key] = !state.areShowns[key]
// }

function hideMapOptions() {
  if (state.mapHidden) {
    state.mapHidden = false
  } else {
    state.mapHidden = true
  }
}

function slideRight() {
  if (!state.sliderMargin <= 0) {
    state.sliderMargin = state.sliderMargin + 100;
  }
}

function slideLeft() {
  state.sliderMargin = state.sliderMargin - 100;
}

// function hideMapOptions() {
//   if (state.venuesHidden) {
//     state.venuesHidden = false
//   } else {
//     state.venuesHidden = true
//   }
// }

function hideVisitOptions() {
  if (state.visitsHidden) {
    state.visitsHidden = false
  } else {
    state.visitsHidden = true
  }
}

function hideAreaOptions() {
  if (state.areaHidden) {
    state.areaHidden = false
  } else {
    state.areaHidden = true
  }
}

function hideTaskOptions() {
  if (state.tasksHidden) {
    state.tasksHidden = false
  } else {
    state.tasksHidden = true
  }
}
</script>
