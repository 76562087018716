import { createWebHistory, createRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import authService from '../services/authService'
import store from '../store'
import { PATHS } from './route-paths'

const history = createWebHistory()
const toast = useToast()

const routes = [
    {
        // LOOK AND FIX THIS
        path: '/',
        redirect: {
            name: 'dashboard',
        },
    },
    {
        path: PATHS.DASHBOARD,
        name: 'dashboard',
        component: () => import('../views/DashboardPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/auth/login.vue'),
        meta: {
            // 🔥 implement feature where an unauthenticated user can go to login page
            requiresUnauth: true,
            layout: 'blank-layout',
        },
    },
    {
        path: '/onboarding',
        name: 'onboarding',
        component: () => import('../views/auth/onboarding.vue'),
        meta: {
            requiresAuth: false,
            layout: 'blank-layout',
        },
    },
    {
        path: '/forgotpassword',
        name: 'forgotpassword',
        component: () => import('../views/auth/forgotpassword.vue'),
        meta: {
            requiresAuth: false,
            layout: 'blank-layout',
        },
    },
    {
        path: '/confirmpasswordreset',
        name: 'confirmpasswordreset',
        component: () => import('../views/auth/confirmpasswordreset.vue'),
        meta: {
            requiresAuth: false,
            layout: 'blank-layout',
        },
    },
    // {
    //     path: '/admin',
    //     name: 'admindashboard',
    //     component: () => import('../views/dashboards/admin.vue'),
    //     meta: {
    //         requiresAuth: true,
    //         layout: 'default-layout-horizontal',
    //     },
    // },
    // {
    //     path: '/user',
    //     name: 'userdashboard',
    //     component: () => import('../views/dashboards/user.vue'),
    //     meta: {
    //         requiresAuth: true,
    //         layout: 'default-layout-horizontal',
    //     },
    // },
    // {
    //     path: '/areas/builder',
    //     name: 'areabuilder',
    //     component: () => import('../views/areas/builder/index.vue'),
    //     meta: {
    //         requiresAuth: true,
    //         layout: 'default-layout-horizontal',
    //     },
    // },

    {
        path: PATHS.VENUES,
        name: 'venues',
        component: () => import('../views/venues/VenuesPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: PATHS.VENUE_DETAIL,
        name: 'venue-detail',
        component: () => import('../views/venues/VenueDetailPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/venues/routes',
        name: 'routes',
        component: () => import('../views/venues/routes/index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/venues/routes/:id',
        name: 'route-manager',
        component: () => import('../views/venues/routemanager/index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },

    // TEMP BOT VIEW
    {
        path: PATHS.BOTS,
        name: 'bots',
        component: () => import('../views/bots/BotsPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: PATHS.BOT_DETAIL,
        name: 'bot-detail',
        component: () => import('../views/bots/BotDetailPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },

    // REPORTS
    {
        path: '/reports/clients/clientconversions',
        name: 'clientconversionreport',
        component: () => import('../views/reports/clients/clientconversions.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/reports/visits/byuser',
        name: 'visitsbyuserreport',
        component: () => import('../views/reports/visits/visitsByUser.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/reports/management/agentperformance',
        name: 'agentperformancereport',
        component: () => import('../views/reports/management/agentperformance.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/reports/management/revenueoverview',
        name: 'revenueoverviewreport',
        component: () => import('../views/reports/management/revenueoverview.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/reports/sales/comissionsummary',
        name: 'comissionsummaryreport',
        component: () => import('../views/reports/sales/comissionsummary.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/reports/advertisers/advertiserchurn',
        name: 'advertiserchurnreport',
        component: () => import('../views/reports/advertisers/advertiserchurn.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/reports/advertisers/advertiserconversions',
        name: 'advertiserconversionreport',
        component: () => import('../views/reports/advertisers/advertiserconversions.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/reports/venues/venuechurn',
        name: 'venuechurnreport',
        component: () => import('../views/reports/venues/venuechurn.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/reports/venues/venueconversions',
        name: 'venueconversionreport',
        component: () => import('../views/reports/venues/venueconversions.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/reports/clients/clientchurn',
        name: 'clientchurnreport',
        component: () => import('../views/reports/clients/clientchurn.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/reports',
        name: 'reports',
        component: () => import('../views/reports/index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/emails',
        name: 'emails',
        component: () => import('../views/emails/index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/emails/templates',
        name: 'emailtemplates',
        component: () => import('../views/emails/templates/index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/visits',
        name: 'visits',
        component: () => import('../views/visits/index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/calls/call-manager',
        name: 'call-manager',
        component: () => import('../views/calls/callmanager/index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: PATHS.AREAS,
        name: 'areas',
        component: () => import('../views/areas/AreasPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: PATHS.AREA_BUILDER,
        name: 'area-builder',
        component: () => import('../views/areas/AreaBuilderPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: PATHS.AREA_DETAIL,
        name: 'area-detail',
        component: () => import('../views/areas/AreaDetailPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/maps/map',
        name: 'maptool',
        component: () => import('../views/maps/map/index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/settings/teams',
        name: 'teams',
        component: () => import('../views/settings/teams.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/venues/map',
        name: 'venuesmap',
        component: () => import('../views/venues/map/index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/areas/map',
        name: 'areasmap',
        component: () => import('../views/areas/map/index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/advertisers/map',
        name: 'advertisersmap',
        component: () => import('../views/advertisers/map/index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: PATHS.CLIENTS,
        name: 'clients',
        component: () => import('../views/clients/ClientsPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: PATHS.CLIENT_DETAIL,
        name: 'client-detail',
        component: () => import('../views/clients/ClientDetailPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/leads/import',
        name: 'leadsimport',
        component: () => import('../views/leads/import/index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/leads/zoomsearch',
        name: 'zoomsearch',
        component: () => import('../views/leads/zoomsearch/index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/billing',
        name: 'billing',
        component: () => import('../views/billing/index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/billing/payments',
        name: 'payments',
        component: () => import('../views/billing/payments/index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/billing/payment',
        name: 'payment',
        component: () => import('../views/billing/payment/index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },

    // ALL ADVERTISERS
    {
        path: PATHS.ADVERTISERS,
        name: 'advertisers',
        component: () => import('../views/advertisers/AdvertisersPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },

    // INDIVIDUAL ADVERTISERS PAGE
    {
        path: PATHS.ADVERTISER_DETAIL,
        name: 'advertiser-detail',
        component: () => import('../views/advertisers/AdvertiserDetailPage.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/tasks',
        name: 'tasks',
        component: () => import('../views/tasks/index.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/actions',
        name: 'actions',
        component: () => import('../views/actions/index.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/books',
        name: 'books',
        component: () => import('../views/books/index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/my/calendar',
        name: 'mycalendar',
        component: () => import('../views/my/calendar/index.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/my/tasks',
        name: 'mytasks',
        component: () => import('../views/my/tasks/index.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/my/visits',
        name: 'myvisits',
        component: () => import('../views/my/visits/index.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/my/emails',
        name: 'myemails',
        component: () => import('../views/my/emails/index.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/payment',
        name: 'externalPayment',
        component: () => import('../views/external/payment/index.vue'),
        meta: {
            requiresAuth: false,
            layout: 'blank-layout',
        },
    },
    {
        path: '/signContract',
        name: 'signContract',
        component: () => import('../views/external/advertisersign/index.vue'),
        meta: {
            requiresAuth: false,
            layout: 'blank-layout',
        },
    },
    {
        path: '/signAgreement',
        name: 'signAgreement',
        component: () => import('../views/external/venuesign/index.vue'),
        meta: {
            requiresAuth: false,
            layout: 'blank-layout',
        },
    },

    {
        path: '/book',
        name: 'book',
        component: () => import('../views/books/book/index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/leads',
        name: 'leads',
        component: () => import('../views/leads/index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/settings/profile',
        name: 'settingprofile',
        component: () => import('../views/settings/profile.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/settings/booktemplates',
        name: 'settingbooktemplates',
        component: () => import('../views/settings/bookTemplates.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/settings/infosheettemplates',
        name: 'settinginfosheettemplates',
        component: () => import('../views/settings/infoSheetTemplates.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/settings/numbertranslations',
        name: 'settingnumbertranslations',
        component: () => import('../views/settings/numberTranslations.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/settings/exchangerates',
        name: 'settingexchangerates',
        component: () => import('../views/settings/exchangeRates.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/settings/visitTypes',
        name: 'settingvisitTypes',
        component: () => import('../views/settings/visitTypes.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/settings/tasks',
        name: 'settingtasks',
        component: () => import('../views/settings/tasks.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/settings/corporateEntities',
        name: 'settingcorporateEntities',
        component: () => import('../views/settings/corporateEntities.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/settings/lists',
        name: 'settinglists',
        component: () => import('../views/settings/lists.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/settings/colors',
        name: 'settingcolors',
        component: () => import('../views/settings/colors.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/settings/permissions',
        name: 'settingpermissions',
        component: () => import('../views/settings/permissions.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/settings/taxRates',
        name: 'settingtaxRates',
        component: () => import('../views/settings/taxRates.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/settings/lists',
        name: 'settinglists',
        component: () => import('../views/settings/lists.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/settings/users',
        name: 'settingusers',
        component: () => import('../views/settings/users.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/settings/actions',
        name: 'settingsactions',
        component: () => import('../views/settings/actions.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/settings/tasks',
        name: 'settingstasks',
        component: () => import('../views/settings/tasks.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/settings/tasksets',
        name: 'settingstasksets',
        component: () => import('../views/settings/taskSets.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/settings/auditlog',
        name: 'settingsauditlog',
        component: () => import('../views/settings/auditlog.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/settings/ratecards',
        name: 'settingsratecards',
        component: () => import('../views/settings/rateCards.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/settings/bot',
        name: 'botsettings',
        component: () => import('../views/settings/bot.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/myprofile',
        name: 'myprofile',
        component: () => import('../views/profile/index.vue'),
        meta: {
            requiresAuth: false,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/areasummarywidget',
        name: 'areasummarywidget',
        component: () => import('../components/dashboards/widgets/areasummary.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/clientsummarywidget',
        name: 'clientsummarywidget',
        component: () => import('../components/dashboards/widgets/clientsummary.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/leadsummarywidget',
        name: 'leadsummarywidget',
        component: () => import('../components/dashboards/widgets/leadsummary.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/venuesummary',
        name: 'venuesummary',
        component: () => import('../components/dashboards/widgets/venuesummary.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/venuesummarychartwidget',
        name: 'venuesummarywidget',
        component: () => import('../components/dashboards/widgets/venuesummarychart.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/leadsummarychartwidget',
        name: 'leadsummarychartwidget',
        component: () => import('../components/dashboards/widgets/leadsummarychart.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/callsettings',
        name: 'callsettings',
        component: () => import('../views/settings/calls.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/areasummarychartwidget',
        name: 'areasummarychartwidget',
        component: () => import('../components/dashboards/widgets/areasummarychart.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/my/notifications',
        name: 'mynotifications',
        component: () => import('../views/my/notifications/index.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
    {
        path: '/client-summary-chart-widget',
        name: 'clientsummarychartwidget',
        component: () => import('../components/dashboards/widgets/clientsummarychart.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },

    {
        path: '/adduserrole',
        name: 'adduserrolewidget',
        component: () => import('../components/users/forms/permissions.vue'),
        meta: {
            requiresAuth: true,
            layout: 'default-layout-horizontal',
        },
    },
]

const router = createRouter({ history, routes })

// sync auth and settings
//if (store.state.token === null){
//    if (localStorage.token === null || localStorage.token === undefined ){
//		this.$router.push({name: 'login'})
//    } else { // fetch settings.
//        // //alert('todo:- get settings')
//    }
//}

//// //alert(JSON.stringify(store.state))

const showLoader = () => {
    // ❓ doesn't work if loadingEl isn't read dynamically...why
    const loadingEl = document.getElementById('global-loader')
    loadingEl.classList.remove('d-none')
}

const hideLoader = () => {
    // ❓ doesn't work if loadingEl isn't read dynamically...why?
    const loadingEl = document.getElementById('global-loader')
    loadingEl.classList.add('d-none')
}

router.beforeEach(async (to, from, next) => {
    showLoader()

    if (!to.meta.requiresAuth) return next()
    if (!store.state.token) return next({ name: 'login' })
    if (!localStorage.token) return next({ name: 'login' })

    const hasAccess = await authService.Check_UserAccess()
    if (!hasAccess) {
        store.commit('logout')
        next({ name: 'login' })
        toast.error('You are no longer authorised, please contact support')
    } else next()
})

router.afterEach(() => setTimeout(hideLoader, 500))

export default router
