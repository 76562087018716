import http from '../http-common'

class searchService {
  List_Installer_GlobalSearchResults(data) {
    return http.post(`/search/List_Installer_GlobalSearchResults/`, data)
  }

  List_Admin_GlobalSearchResults(data) {
    return http.post(`/search/List_Admin_GlobalSearchResults/`, data)
  }

  List_All_Installer_GlobalSearchResults(data) {
    return http.post(`/search/List_All_Installer_GlobalSearchResults/`, data)
  }

  List_All_Admin_GlobalSearchResults(data) {
    return http.post(`/search/List_All_Admin_GlobalSearchResults/`, data)
  }

  Search_CRM(searchValue) {
    return http.get(`/search/Search_CRM/` + searchValue)
  }
}

export default new searchService()
