import http from '../http-common'

class loggingService {
  Log_UserConnection(id) {
    return http.get('logging/Log_UserConnection/' + id)
  }

  List_AuditLog(params) {
    return http.post(`logging/List_AuditLog`, params)
  }

  Log_Error(data) {
    return http.post(`logging/Log_Error`, data)
  }
}

export default new loggingService()
