<template>
  <!-- Main navbar -->
  <div class="main-header dark-header side-header sticky nav nav-item">
    <div class="main-header dark-header side-header sticky nav nav-item">
      <div class="container-fluid">
        <div class="main-header-left">
          <div class="">
            <!-- <img src="../../assets/images/ppmLogo.png" style="height:48px;" class="main-logo desktop-dark" alt=""> -->
          </div>
          <div class="main-header-center ms-4 form-group">
            <admin-search></admin-search>
          </div>
        </div>
        <div class="main-header-right">
          <div class="mb-0 navbar navbar-expand-lg navbar-nav-right navbar-dark p-0">
            <notifications></notifications>
            <ul @click="toggleTopMenu('profile')" class="dropdown main-profile-menu nav nav-item nav-link ps-lg-2">
              <li class="dropdown main-profile-menu nav nav-item nav-link ps-lg-2">
                <a
                  href="#"
                  class="new nav-link profile-user d-flex show"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src="/images/avatar-1.png" class="rounded-pill" style="margin-right: 5px" height="34" alt="" />
                </a>
                <div class="dropdown-menu" :class="{ show: state.showProfileMenu }">
                  <div class="menu-header-content p-3 border-bottom">
                    <div class="d-flex wd-100p">
                      <div class="main-img-user">
                        <img src="/images/avatar-1.png" class="rounded-pill mr-lg-2" height="34" alt="" />
                      </div>
                      <div class="ms-3 my-auto">
                        <h6 class="tx-15 font-weight-semibold mb-0">
                          {{ store.state.settings.firstName + ' ' + store.state.settings.lastName }}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <a class="dropdown-item"
                    ><router-link :to="{ name: 'settingprofile' }"
                      ><i class="far fa-user-circle"></i> My profile</router-link
                    ></a
                  >
                  <a class="dropdown-item"
                    ><router-link :to="{ name: 'myvisits' }"
                      ><i class="far fa-user-circle"></i> My visits</router-link
                    ></a
                  >
                  <a class="dropdown-item"
                    ><router-link :to="{ name: 'settingprofile' }"
                      ><i class="far fa-sun"></i> Account settings</router-link
                    ></a
                  >
                  <a @click="exitApp" class="dropdown-item"><i class="far fa-arrow-alt-circle-left"></i> Logout</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /main navbar -->
</template>
<script>
import { useStore } from 'vuex'
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import Notifications from '../components/generic/notifications/notifications.vue'
import authService from '../services/authService'
import { useToast } from 'vue-toastification'

export default {
  components: { Notifications },
  setup(props) {
    const router = useRouter()

    const state = reactive({
      showPolicyConfigDropdown: false,
      showAdminDropdown: false,
      showRegReportsDropdown: false,
      showInstallerReportsDropdown: false,
      showProfileMenu: false,
    })
    const toast = useToast()
    const store = useStore()
    const closeMenus = function () {
      state.showPolicyConfigDropdown = false
      state.showAdminDropdown = false
      state.showProfileMenu = false
    }
    const exitApp = function () {
      authService
        .logout(store.state.token)
        .then(() => {
          store.commit('logout')
          router.push({ name: 'login' })
        })
        .catch((err) => {
          toast.error('There has been an error')
        })
    }
    const toggleTopMenu = function (name) {
      state.showPolicyConfigDropdown = !state.showPolicyConfigDropdown && name === 'policyConfig' ? true : false
      state.showAdminDropdown = !state.showAdminDropdown && name === 'admin' ? true : false
      state.showProfileMenu = !state.showProfileMenu && name === 'profile' ? true : false
    }
    return { store, state, toggleTopMenu, exitApp }
  },
}
</script>
