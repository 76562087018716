import axios, { AxiosInstance, AxiosResponse } from 'axios'
import store from './store'
import { useToast } from 'vue-toastification'
import { useRouter, useRoute } from 'vue-router'
import { nextTick } from 'vue'
import authService from './services/authService'

const toast = useToast()
const router = useRouter()
const route = useRoute()

const apiClient = axios.create({
  baseURL: import.meta.env.VITE_API_URI,
  headers: {
    'Content-type': 'application/json',
  },
})

apiClient.interceptors.request.use(function (config) {
  store.commit('axiosIsLoading')
  if (store.state.token != null) {
    const token = store.state.token
    config.headers = {}
    config.headers.Authorization = token ? `Bearer ${token}` : ''
  }

  //_instance.app.config.globalProperties.$Progress.start()

  return config
})

// ########################################################################## code between is intercepton 401.. need to validate
const responseSuccessHandler = (res) => {
  store.commit('axiosNotLoading')

  return res.data
}

const responseErrorHandler = async (err) => {
  store.commit('axiosNotLoading')

  const reqConfig = err.response.config
  const code = err.response.status
  const statusText = err.response.statusText
  const apiErr = err.response.data

  const isAuthErr = [401, 403].includes(code)

  if (document.URL.indexOf('/login') > 0) {
  } else if (isAuthErr) {
    await authService.logout(store.state.token)
    store.commit('logout')
    window.location.href = '/login'
  } else if (err.message) {
    // toast.error(err.message)
  }

  return Promise.reject(apiErr)
}

apiClient.interceptors.response.use(responseSuccessHandler, responseErrorHandler)
// ########################################################################## code between is intercepton 401.. need to validate

export default apiClient
