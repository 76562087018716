import { createApp } from 'vue'
import Antd from 'ant-design-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast, { POSITION } from 'vue-toastification'
import VueGoodTablePlugin from 'vue-good-table-next'
import VueApexCharts from 'vue3-apexcharts'
import VueProgressBar from '@aacassandra/vue3-progressbar'
import VueCreditCardValidation from 'vue-credit-card-validation'
import PrimeVue from 'primevue/config'

import mitt from 'mitt'
import moment from 'moment-timezone'
import UUID from 'vue3-uuid'
import Avatar from 'vue3-avatar'
import VCalendar from 'v-calendar'
import SignaturePad from 'vue3-signature-pad'
// font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fab)
import { far } from '@fortawesome/free-regular-svg-icons'
library.add(far)
import { dom } from '@fortawesome/fontawesome-svg-core'
dom.watch()
// end font awesome

//styles
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
// import './assets/css/icons/icomoon/styles.min.css'
import 'vue-multiselect/dist/vue-multiselect.css'
import 'ant-design-vue/dist/antd.css'
import 'vue-toastification/dist/index.css'
import 'vue-json-pretty/lib/styles.css'
import 'vue-good-table-next/dist/vue-good-table-next.css'
import 'v-calendar/dist/style.css'
import '../node_modules/vue-simple-calendar/dist/style.css'
import '../node_modules/vue-simple-calendar/static/css/default.css'
import 'primevue/resources/themes/aura-light-green/theme.css'

// core styles
import './assets/css/skin-modes.scss'
import './assets/css/style-dark.scss'
import './assets/scss/style.scss'
import './assets/css/styleoverrides.css'
import './assets/icons/feather/feather.css'

const emitter = mitt()

export const app = createApp(App)

app.use(VueGoodTablePlugin)
app.component('font-awesome-icon', FontAwesomeIcon)
// not sure about this.. part of antdv ?? -->>>>> app.config.productionTip = false;

app.config.globalProperties.$hasRole = (role) => {
    const roles = store.state.settings?.roles
    if (!roles) return false

    return roles.some((r) => r.id.toLowerCase() === role.toLowerCase())
}

app.config.globalProperties.$hasPermission = (permission) => {
    const permissions = store.state.settings?.permissions
    if (!permissions) return false

    return permissions.some((r) => r.permissionName.toLowerCase() === permission.toLowerCase())
}

app.provide('emitter', emitter)
app.provide('$moment', moment)

app.use(VCalendar)
app.use(VueApexCharts)

app.use(Toast, {
    position: POSITION.TOP_RIGHT,
    timeout: 2000,
})

app.use(VueProgressBar, {
    color: '#bffaf3',
    failedColor: '#874b4b',
    thickness: '3px',
    transition: { speed: '0.2s', opacity: '0.6s', termination: 300 },
    autoRevert: true,
    location: 'top',
    inverse: false,
})

app.use(Antd)
app.use(PrimeVue)
app.use(VueCreditCardValidation)
app.use(SignaturePad)
app.use(UUID)
app.component('avatar', Avatar)

app.use(store)
app.use(router).mount('#app')
