<template>
  <div>
    <a-popover trigger="click" v-model:visible="state.isShown">
      <template #title>
        <!-- <span>Notifications</span> -->
        <div class="d-flex p-2" style="position: relative; width: 250px">
          <h6 style="margin-top: 5px" class="dropdown-title mb-1 tx-15 font-weight-semibold">Notifications</h6>
          <a style="cursor: pointer; position: absolute; top: 5px; right: -5px"
            ><span @click="markAllRead" class="badge badge-pill badge-dark ms-auto my-auto float-end"
              >Clear All</span
            ></a
          >
        </div>
        <p class="ps-2 dropdown-title-text subtext mb-0 op-6 pb-0 tx-12">
          You have {{ state.notifications.length }} Notifications
        </p>
      </template>

      <template #content>
        <div
          class="main-notification-list Notification-scroll ps fadyItem"
          v-if="state.notifications && state.notifications.length"
        >
          <TransitionGroup name="slide-fade">
            <a @click="goToPage(a)" v-for="a in state.notifications" :key="a.id" class="d-flex p-3 border-bottom">
              <div class="notifyimg bg-primary"><i style="margin: 11px" class="far fa-flag text-white"></i></div>
              <div class="ms-3">
                <h5 class="notification-label mb-1">{{ a.content }}</h5>
                <div class="notification-subtext">{{ moment(a.createdDate).fromNow() }}</div>
              </div>
              <div class="ms-auto" style="padding-left: 15px">
                <a @click.stop.prevent="clearNotification(a)"
                  ><i class="fa fa-trash text-danger float-end" style="margin-top: 4px"></i
                ></a>
              </div> </a
          ></TransitionGroup>
          <div class="ps__rail-x" style="left: 0px; bottom: 0px">
            <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px"></div>
          </div>
          <div class="ps__rail-y" style="top: 0px; right: 0px">
            <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px"></div>
          </div>
        </div>
        <div class="dropdown-footer">
          <a @click="goToNotifications" class="btn btn-primary text-light btn-sm btn-block">VIEW ALL</a>
        </div>
        <!-- <div class="row" style="width: 300px; max-height: 250px; overflow-y: scroll">
                    <div class="col-12" v-if="state.notifications && state.notifications.length">
                        <p v-for="a in state.notifications" :key="a.id">
                            <a @click="goToPage(a)" class="text-primary">{{ a.content }}</a>
                            <a @click="clearNotification(a)"><i class="fa fa-trash text-danger float-end" style="margin-top: 4px"></i></a>
                        </p>
                    </div>
                    <div class="col-12" v-else>
                        <p class="text-center">No notifications found.</p>
                    </div>
                </div> -->
      </template>
      <a class="new nav-link"><i class="far fa-bell text-white" style="font-size: 20px"></i></a>
      <span
        class="badge badge-danger badge-pill"
        style="position: absolute; bottom: 20px; left: 20px"
        v-if="state.notifications && state.notifications.length > 0"
        >{{ state.notifications.length }}</span
      >
      <span
        class="badge badge-success badge-pill"
        style="position: absolute; bottom: 20px; left: 20px"
        v-if="state.notifications && state.notifications.length === 0"
        >{{ state.notifications.length }}</span
      >
    </a-popover>
  </div>
</template>

<script>
import { onUnmounted, reactive, inject } from 'vue'
import notificationService from '../../../services/notificationService'
import { POSITION, useToast } from 'vue-toastification'
import { useRouter } from 'vue-router'

export default {
  setup() {
    const state = reactive({
      notifications: [],
      isShown: false,
    })

    const moment = inject('$moment')

    const toast = useToast()

    const router = useRouter()

    let timer

    function markAllRead() {
      state.notifications.forEach((notification) => {
        clearNotification(notification, true)
      })
      listNotifications()
    }

    function listNotifications() {
      notificationService
        .List_UserNotifications()
        .then((data) => {
          state.notifications = data
        })
        .catch((error) => {
          toast.error('There has been an error')
        })
    }

    listNotifications()

    function clearNotification(_notification, isBatch) {
      _notification.deleteRecord = true
      notificationService
        .Set_Notification(_notification)
        .then(() => {
          if (!isBatch) {
            toast.success('Notification Cleared', { position: POSITION.BOTTOM_RIGHT })
            listNotifications()
          }
        })
        .catch((error) => {
          toast.error('There has been an error')
        })
    }

    function setTimer() {
      timer = setInterval(listNotifications, 12000)
    }

    setTimer()

    function goToPage(_notification) {
      clearNotification(_notification, false)
      if (_notification.advertiserId) {
        router.push({ name: 'advertiser-detail', params: { id: _notification.advertiserId } })
      } else if (_notification.venueId) {
        router.push({ name: 'venue-detail', params: { id: _notification.venueId } })
      } else if (_notification.clientId) {
        router.push({ name: 'client-detail', params: { id: _notification.clientId } })
      } else if (_notification.bookId) {
        router.push({ name: 'book', query: { id: _notification.bookId } })
      }
    }

    function goToNotifications() {
      state.isShown = false
      router.push({ name: 'mynotifications' })
    }

    onUnmounted(() => {
      clearInterval(timer)
    })

    return {
      state,
      goToPage,
      clearNotification,
      markAllRead,
      moment,
      goToNotifications,
    }
  },
}
</script>
