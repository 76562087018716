<template>
    <div>
        <img v-if="!fileUrl" class="" style="border-radius: 5px" :style="{ width: width + 'px', height: height + 'px' }" src="../../../assets/images/avatar-1.png" width="130" height="130" alt="" />
    </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import { useStore } from 'vuex'
import attachmentsService from '../../../services/attachments-service'
const store = useStore()
const toast = useToast()
export default {
    watch: {
        files: function () {
            this.processFiles()
        },
    },
    props: {
        userId: {
            type: String,
            default: null,
        },
        width: {
            type: Number,
            default: 100,
        },
        height: {
            type: Number,
            default: 100,
        },
        displayOnly: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            files: [],
            fileUrl: null,
            attachments: null,
            render: true,
            newAttachment: { content: '' },
        }
    },
    mounted() {},
    methods: {
        deleteAttachment(attachment) {
            attachment.deleteRecord = true
            attachmentsService
                .Delete_Attachment(this.entityName, attachment)
                .then(() => {
                    this.getAttachments()
                    toast.success('Attachment Deleted')
                })
                .catch(() => {
                    this.$message.error('There was an error deleting this attachment')
                })
        },
        finishedUploading() {
            this.files = []
            this.getAvatar()
        },
        processFiles() {
            if (this.files.length) {
                this.startUpload(this.files[0])
            }
        },
        startUpload(file) {
            this.upload(file, (event) => {
                this.progress = Math.round((100 * event.loaded) / event.total)
            })
        },
        upload(file) {
            let formData = new FormData()
            formData.append('entityId', this.$store.state.settings.userId)
            formData.append('file', file)
            attachmentsService
                .Set_Avatar(formData)
                .then(() => {
                    setTimeout(() => this.finishedUploading(), 3000)
                })
                .catch((error) => {
                    this.finishedUploading()
                    if (error != null && error.message != null) {
                        toast.error(error.message)
                    } else {
                        toast.error('Could not upload the file. Please contact support!')
                    }
                })
        },
        removeAndShowSpareIcon() {
            this.fileUrl = null
            if (document.querySelector('#avatar' + this.width.toString() + this.height.toString())) {
                document.querySelector('#avatar' + this.width.toString() + this.height.toString()).classList.add('d-none')
            }
        },
        onChange() {
            this.files = [...this.$refs.fileUpload.files]
        },
        openFileSelect() {
            this.$refs.fileUpload.click()
        },
        resetAttachment() {
            this.files = []
            this.attachments = null
            this.newAttachment = { content: '' }
        },
        imagePath(img) {
            const imgUrl = new URL(img, import.meta.url)
            return imgUrl
        },
    },
}
</script>

<style scoped>
.card-img-actions-overlay:hover {
    display: block;
}
</style>
