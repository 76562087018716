import http from '../http-common'

class notificationService {
  List_Announcements() {
    return http.get(`/Notifications/List_Announcements`)
  }

  List_Admin_Notifications() {
    return http.get(`/Notifications/List_Admin_Notifications`)
  }

  List_UserNotifications() {
    return http.get(`/Notifications/List_UserNotifications`)
  }

  List_AllUserNotifications(data) {
    return http.post(`/Notifications/List_AllUserNotifications`, data)
  }

  Set_Notification(data) {
    return http.post(`/Notifications/Set_Notification`, data)
  }

  List_Admin_Active_Notifications() {
    return http.get(`/Notifications/List_Admin_Active_Notifications`)
  }

  Set_Announcement(data) {
    return http.post(`/Notifications/Set_Announcement`, data)
  }

  Set_Admin_Notification(data) {
    return http.post(`/Notifications/Set_Admin_Notification`, data)
  }
}

export default new notificationService()
